<template>
  <v-dialog
    :value="dialog_detail"
    @click:outside="$emit('DailogClose')"
    @keydown.esc="$emit('DailogClose')"
    max-width="1000px"
  >
    <v-card>
      <v-card-title>
        <span class="headline"></span>
      </v-card-title>

      <v-card-text>
        <v-row class="my-1">
          <v-col cols="9">
            <v-row>
              <v-col cols="4">
                <span class="title">{{ select_item.order }}</span>
              </v-col>
              <v-spacer></v-spacer>
              <v-btn
                class="mt-2 pa-2"
                color="blue darken-1"
                large
                icon
                @click="
                  pageMove(
                    'order',
                    select_item.company,
                    select_item.order,
                    select_item._id,
                  )
                "
              >
                <v-icon>mdi-arrow-right-bold</v-icon>
              </v-btn>
              <v-col cols="12">
                {{ select_item.client && select_item.client.name }},
                {{ select_item.item && select_item.item.name }} ({{
                  select_item.item && select_item.item.name_production
                }})
              </v-col>
            </v-row>
          </v-col>
          <v-divider vertical />
          <v-col cols="3">
            <v-row>
              <v-col cols="6">
                <span class="title">클레임</span>
              </v-col>
              <v-spacer></v-spacer>
              <v-btn
                class="mt-2 pa-2"
                color="blue darken-1"
                large
                icon
                @click="
                  pageMove(
                    'claim',
                    select_item.company,
                    select_item.order,
                    select_item._id,
                  )
                "
              >
                <v-icon>mdi-arrow-right-bold</v-icon>
              </v-btn>
              <v-col cols="12"
                >총 {{ select_item.claimLength }}건 있습니다.</v-col
              >
            </v-row>
          </v-col>
        </v-row>
        <v-divider />
        <v-row class="my-1">
          <v-col cols="6">
            <span class="title">매출 / 매입</span>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn
            class="mt-2 pa-2"
            color="blue darken-1"
            large
            icon
            @click="
              pageMove(
                'production',
                select_item.company,
                select_item.order,
                select_item._id,
              )
            "
          >
            <v-icon>mdi-arrow-right-bold</v-icon>
          </v-btn>
          <v-col cols="12">
            <v-data-table
              :headers="salesHeader"
              :items="select_item.sales"
              item-key="_id"
              hide-default-footer
              disable-sort
              no-data-text="현재 마감 된 매출이 없습니다."
            >
              <template v-slot:[`item`]="{ item }">
                <tr>
                  <td class="text-center">
                    {{ item.place_sales.name }}
                  </td>
                  <td class="text-right">
                    {{
                      `${item.sales_total_quantity}${select_item.unit_sign}`
                        | makeComma
                    }}
                  </td>
                  <td class="text-right">
                    {{
                      `${select_item.currency_sign}${select_item.sales_unit_price}`
                        | makeComma
                    }}
                  </td>
                  <td class="text-right">
                    {{
                      `${select_item.currency_sign}${item.sales_total_amount}`
                        | makeComma
                    }}
                  </td>
                </tr>
              </template>
              <template v-slot:[`body.append`]>
                <tr>
                  <th></th>
                  <th></th>
                  <th class="text-center">매출 합</th>
                  <th class="text-right">
                    {{
                      `${select_item.currency_sign}${
                        select_item.salesSumTotalAmount
                          ? select_item.salesSumTotalAmount
                          : 0
                      }` | makeComma
                    }}
                  </th>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <!-- <v-divider /> -->
        <v-row class="mt-1">
          <!-- <v-col cols="6">
              <span class="title">매입</span>
            </v-col>
            <v-spacer></v-spacer> -->
          <!-- <v-btn class="mt-1" color="blue darken-1" large text> 이동 </v-btn> -->
          <v-col cols="12">
            <v-data-table
              :headers="purchaseHeader"
              :items="select_item.purchase"
              item-key="_id"
              hide-default-footer
              disable-sort
              no-data-text="현재 마감 된 매입이 없습니다."
            >
              <template v-slot:[`item`]="{ item }">
                <tr>
                  <td class="text-center">
                    {{ item.place_forward.name }}
                  </td>
                  <td class="text-right">
                    {{
                      `${item.total_quantity}${select_item.unit_sign}`
                        | makeComma
                    }}
                  </td>
                  <td class="text-right">
                    {{
                      `${select_item.currency_sign}${item.color[0].unit_price}`
                        | makeComma
                    }}
                  </td>
                  <td class="text-right">
                    {{
                      `${select_item.currency_sign}${
                        item.total_quantity * item.color[0].unit_price
                      }` | makeComma
                    }}
                  </td>
                </tr>
              </template>
              <template v-slot:[`body.append`]>
                <tr>
                  <th></th>
                  <th></th>
                  <th class="text-center">매입 합</th>
                  <th class="text-right">
                    {{
                      `${select_item.currency_sign}${
                        select_item.purchase_amount
                          ? select_item.purchase_amount
                          : 0
                      }` | makeComma
                    }}
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th class="text-center">매출 이익</th>
                  <th class="text-right">
                    {{
                      `${select_item.currency_sign}${
                        select_item.salesSumTotalAmount &&
                        select_item.purchase_amount
                          ? select_item.salesSumTotalAmount -
                            select_item.purchase_amount
                          : 0
                      }` | makeComma
                    }}
                  </th>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="mt-1 pa-2"
          color="blue darken-1"
          text
          @click="$emit('DailogClose')"
        >
          닫기
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import purchaseHeader from "@/assets/table/main/purchase";
import salesHeader from "@/assets/table/main/sales";
export default {
  props: {
    dialog_detail: Boolean,
    select_item: {},
  },
  data() {
    return {
      purchaseHeader,
      salesHeader,
    };
  },
  methods: {
    // dialog_detail
    pageMove(path, company, order, _id) {
      this.$router.push({
        name: path,
        params: { company, order, _id },
      });
    },
  },
};
</script>
