// organisms
import LoadingSpinner from "@/components/organisms/LoadingSpinner/LoadingSpinner";

import SnackBar from "@/components/organisms/SnackBar/SnackBar";
// main
// import TopCardForm from "./TopCardForm";
import MainOrderList from "@/components/organisms/FilterList/MainOrderList.vue";
import MainTopTable from "@/components/pages/main/MainTopTable";
import MainDataDialog from "./MainDataDialog";
import trialDialog from "@/components/organisms/VDialog/TrialAlramDialog.vue";
import GraphPage from "./GraphPage.vue";
import numFilterDialog from "@/components/organisms/VDialog/numFilterDialog";
import { getTriralCookie } from "@/utils/cookies";
export default {
  components: {
    LoadingSpinner,
    // TopCardForm,
    MainOrderList,
    MainTopTable,
    MainDataDialog,
    SnackBar,
    numFilterDialog,
    GraphPage,
    trialDialog,
  },
  data() {
    return {
      select_item: {},
      isLoading: true,
      snackbar: false,
      snackbar_text: "",
      timeout: 1500,
      quarters: [
        {
          label: "1분기 (1월 ~ 3월)",
          value: "firstQuarter",
        },
        {
          label: "2분기 (4월 ~ 6월)",
          value: "secondQuarter",
        },
        {
          label: "3분기 (7월 ~ 9월)",
          value: "thirdQuarter",
        },
        {
          label: "4분기 (10월 ~ 12월)",
          value: "fourthQuarter",
        },
      ],
      viewType: [
        {
          label: "그래프형",
          value: "graph",
        },
        {
          label: "테이블형",
          value: "table",
        },
      ],
      selectType: "graph",
      seasons: [
        {
          value: "none",
          label: "선택 안함",
        },
        {
          value: "ss",
          label: "S/S",
        },
        {
          value: "fw",
          label: "F/W",
        },
      ],
      selectYear: "2024",
      selectClient: "",
      selectSeason: "",
      selectQuarter: "",
    };
  },
  computed: {},

  methods: {
    async changeViewSelect() {
      await this.$store.dispatch("GET_MAIN_CHART_DATA", {
        year: this.selectYear,
        season: this.selectSeason,
        client: this.selectClient,
      });
    },
  },
  async created() {
    this.$store.dispatch("SET_API_LOADING", true);

    await this.$store.dispatch("GET_MAIN_CHART_DATA", {
      year: this.selectYear,
      season: this.selectSeason,
      client: this.selectClient,
    });
    this.$store.dispatch("ORIGIN_LIST");
    this.$store.dispatch("SET_CLIENTLIST");

    this.$store.dispatch("SET_API_LOADING", false);
  },
  beforeDestroy() {
    this.$store.commit("setMainOrderList");
  },
  mounted() {
    const isTrial = JSON.parse(getTriralCookie());

    if (isTrial) this.$store.commit("setTrialAlramDialog", true);
  },
};
