export default [
  {
    text: "매출처",
    value: "place_sales.name",
    width: "25%",
    align: "center",
  },
  {
    text: "수량",
    value: "sales_total_quantity",
    width: "25%",
    align: "center",
  },
  {
    text: "단가",
    value: "sales_unit_price",
    width: "25%",
    align: "center",
  },
  {
    text: "금액",
    value: "sales_total_amount",
    width: "25%",
    align: "center",
  },
];
