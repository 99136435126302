import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";
export default {
  props: ["selectYear"],
  computed: {
    ...mapGetters({
      mainchartData: "getCurrentYearChartData",
      lastYearChartData: "getLastYearChartData",
    }),
    quantityTotal() {
      return `${this.comma(
        this.roundsData(
          this.mainchartData.reduce((r, c) => r + c.totalQuantity, 0),
        ),
      )}`;
    },
    manuquantityTotal() {
      return `${this.comma(
        this.roundsData(
          this.mainchartData.reduce((r, c) => r + c.totalManuQuantity, 0),
        ),
      )}`;
    },
    relquantityTotal() {
      return `${this.comma(
        this.roundsData(
          this.mainchartData.reduce((r, c) => r + c.totalRelQuantity, 0),
        ),
      )}`;
    },
    purquantityTotal() {
      return `${this.comma(
        this.roundsData(
          this.mainchartData.reduce((r, c) => r + c.totalPurQuantity, 0),
        ),
      )}`;
    },
    salesquantityTotal() {
      return `${this.comma(
        this.roundsData(
          this.mainchartData.reduce((r, c) => r + c.totalSaleQuantity, 0),
        ),
      )}`;
    },
    purchaseData() {
      return [
        {
          name: "매입 건수",
          data: this.mainchartData.map((data) => data.totalPurchaseCount),
        },
      ];
    },
    purchaseOptions() {
      return {
        chart: {
          height: 185,
          type: "area",
          toolbar: {
            show: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: this.mainchartData.map((data) => data._id),
        },
        yaxis: {
          labels: {
            show: false,
            align: "right",
            minWidth: 0,

            style: {
              colors: [],
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-yaxis-label",
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            formatter: (value) => {
              return this.comma(value);
            },
          },
        },
        colors: ["#008FFB"],
        tooltip: {
          x: {},
        },
        title: {
          text: `${this.comma(
            this.mainchartData.reduce((r, c) => r + c.totalPurchaseCount, 0),
          )}`,
          offsetX: 0,
          style: {
            fontSize: "24px",
            cssClass: "apexcharts-yaxis-title",
          },
        },
        subtitle: {
          text: "건수",
          offsetX: 0,
          style: {
            fontSize: "14px",
            cssClass: "apexcharts-yaxis-title",
          },
        },
      };
    },
    purchasePriceData() {
      return [
        {
          name: "매입 금액",

          data: this.mainchartData.map((data) => data.totalPurchasePrice),
        },
      ];
    },
    purchasePriceChartOption() {
      return {
        chart: {
          height: 185,
          type: "area",
          toolbar: {
            show: false,
          },

          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          // enabled: true,
          // formatter: (value) => {
          //   return value ? `₩${this.comma(value)}` : "₩0";
          // },
          // style: {
          //   colors: [],
          //   fontSize: "12px",
          //   fontFamily: "Helvetica, Arial, sans-serif",
          //   fontWeight: 400,
          //   cssClass: "apexcharts-yaxis-label",
          // },
        },
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: this.mainchartData.map((data) => data._id),
        },
        yaxis: {
          labels: {
            show: false,
            align: "right",
            minWidth: 0,

            style: {
              colors: [],
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-yaxis-label",
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            formatter: (value) => {
              return `₩${this.comma(value)}`;
            },
          },
        },
        colors: ["#008FFB"],
        tooltip: {
          x: {},
        },
        title: {
          text: `₩${this.comma(
            this.roundsData(
              this.mainchartData.reduce((r, c) => r + c.totalPurchasePrice, 0),
            ),
          )}`,
          offsetX: 0,
          style: {
            fontSize: "24px",
            cssClass: "apexcharts-yaxis-title",
          },
        },
        subtitle: {
          text: "매입금액",
          offsetX: 0,
          style: {
            fontSize: "14px",
            cssClass: "apexcharts-yaxis-title",
          },
        },
      };
    },
    saleData() {
      return [
        {
          name: "매출 건수",

          data: this.mainchartData.map((data) => data.totalSalesCount),
        },
      ];
    },
    saleChartOption() {
      return {
        chart: {
          height: 185,
          type: "area",
          toolbar: {
            show: false,
          },

          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: this.mainchartData.map((data) => data._id),
        },
        yaxis: {
          labels: {
            show: false,
            align: "right",
            minWidth: 0,

            style: {
              colors: [],
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-yaxis-label",
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            formatter: (value) => {
              return `${this.comma(value)}`;
            },
          },
        },
        colors: ["#008FFB"],
        tooltip: {
          x: {},
        },
        title: {
          text: `${this.comma(
            this.roundsData(
              this.mainchartData.reduce((r, c) => r + c.totalSalesCount, 0),
            ),
          )}`,
          offsetX: 0,
          style: {
            fontSize: "24px",
            cssClass: "apexcharts-yaxis-title",
          },
        },
        subtitle: {
          text: "건수",
          offsetX: 0,
          style: {
            fontSize: "14px",
            cssClass: "apexcharts-yaxis-title",
          },
        },
      };
    },
    salePriceData() {
      return [
        {
          name: "매출 금액",

          data: this.mainchartData.map((data) => data.totalSalesPrice),
        },
      ];
    },
    salePriceChartOptions() {
      return {
        chart: {
          height: 185,
          type: "area",
          toolbar: {
            show: false,
          },

          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: this.mainchartData.map((data) => data._id),
        },
        yaxis: {
          labels: {
            show: false,
            align: "right",
            minWidth: 0,

            style: {
              colors: [],
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-yaxis-label",
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            formatter: (value) => {
              return `₩${this.comma(value)}`;
            },
          },
        },
        colors: ["#008FFB"],
        tooltip: {
          x: {},
        },
        title: {
          text: `₩${this.comma(
            this.roundsData(
              this.mainchartData.reduce((r, c) => r + c.totalSalesPrice, 0),
            ),
          )}`,
          offsetX: 0,
          style: {
            fontSize: "24px",
            cssClass: "apexcharts-yaxis-title",
          },
        },
        subtitle: {
          text: "매출금액",
          offsetX: 0,
          style: {
            fontSize: "14px",
            cssClass: "apexcharts-yaxis-title",
          },
        },
      };
    },
    barchartData() {
      return [
        {
          name: "매입",
          data: this.mainchartData.map((data) => data.totalPurchasePrice),
        },
        {
          name: "매출",
          data: this.mainchartData.map((data) => data.totalSalesPrice),
        },
      ];
    },
    labelChartOptions() {
      return {
        chart: {
          height: 350,
          type: "line",

          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#77B6EA", "#545454"],
        dataLabels: {
          //   enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "전년도 대비 매출금액",
          align: "left",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: [
            "1월",
            "2월",
            "3월",
            "4월",
            "5월",
            "6월",
            "7월",
            "8월",
            "9월",
            "10월",
            "11월",
            "12월",
          ],
        },
        yaxis: {
          title: {
            text: "단위(천원)",
          },

          labels: {
            show: true,
            align: "right",
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: [],
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-yaxis-label",
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            formatter: (value) => {
              return value ? `${this.comma(Math.round(value / 1000))}` : `₩0`;
            },
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      };
    },
    labelChartData() {
      return [
        {
          name: `${this.selectYear}년 매출`,
          data: this.mainchartData.map((data) => data.totalSalesPrice),
        },
        {
          name: `${this.selectYear - 1}년 매출`,
          data: this.lastYearChartData.map((data) => data.totalSalesPrice),
        },
      ];
    },

    currentSalesPriceData() {
      return this.roundsData(
        this.mainchartData.reduce((r, c) => r + c.totalSalesPrice, 0),
      );
    },
    currentPurchasePriceData() {
      return this.roundsData(
        this.mainchartData.reduce((r, c) => r + c.totalPurchasePrice, 0),
      );
    },
    currentProfitPrice() {
      return this.roundsData(
        this.currentSalesPriceData - this.currentPurchasePriceData,
      );
    },
    lastYearSalesPriceData() {
      return this.roundsData(
        this.lastYearChartData.reduce((r, c) => r + c.totalSalesPrice, 0),
      );
    },
    lastYearPurchasePriceData() {
      return this.roundsData(
        this.lastYearChartData.reduce((r, c) => r + c.totalPurchasePrice, 0),
      );
    },
    lastYearProfitPrice() {
      return this.roundsData(
        this.lastYearSalesPriceData - this.lastYearPurchasePriceData,
      );
    },
    curretYearMargin() {
      return this.currentSalesPriceData
        ? parseFloat(
            (
              (this.currentProfitPrice / this.currentSalesPriceData) *
              100
            ).toFixed(1),
          )
        : 0;
    },
    lastYearMargin() {
      return this.lastYearSalesPriceData
        ? parseFloat(
            (
              (this.lastYearProfitPrice / this.lastYearSalesPriceData) *
              100
            ).toFixed(1),
          )
        : 0;
    },
    salesPriceRate() {
      let priceRate = 0;
      if (this.currentSalesPriceData)
        priceRate = this.lastYearSalesPriceData
          ? this.roundsData(
              ((this.currentSalesPriceData - this.lastYearSalesPriceData) /
                (this.lastYearSalesPriceData < 0
                  ? Math.abs(this.lastYearSalesPriceData)
                  : this.lastYearSalesPriceData)) *
                100,
            )
          : 100;
      return priceRate;
    },
    profitRate() {
      let profitRate = 0;
      if (this.currentProfitPrice)
        profitRate = this.lastYearProfitPrice
          ? this.roundsData(
              ((this.currentProfitPrice - this.lastYearProfitPrice) /
                (this.lastYearProfitPrice < 0
                  ? Math.abs(this.lastYearProfitPrice)
                  : this.lastYearProfitPrice)) *
                100,
            )
          : 100;
      return profitRate;
    },
    marginRate() {
      let marginRate = 0;
      if (this.curretYearMargin)
        marginRate = this.lastYearMargin
          ? this.roundsData(
              ((this.curretYearMargin - this.lastYearMargin) /
                (this.lastYearMargin < 0
                  ? Math.abs(this.lastYearMargin)
                  : this.lastYearMargin)) *
                100,
            )
          : 100;
      return marginRate;
    },
  },
  components: {
    apexcharts: VueApexCharts,
  },
  data() {
    return {
      barchartOptions: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "1월",
            "2월",
            "3월",
            "4월",
            "5월",
            "6월",
            "7월",
            "8월",
            "9월",
            "10월",
            "11월",
            "12월",
          ],
        },
        yaxis: {
          title: {
            text: "단위 (천원)",
          },
          labels: {
            show: true,
            align: "right",
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: [],
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-yaxis-label",
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            formatter: (value) => {
              return this.comma(Math.round(value / 1000));
            },
          },
        },

        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "₩" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); // 천단위 콤마
            },
          },
        },
      },
    };
  },
  async created() {},
};
