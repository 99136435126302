<template>
  <v-container fluid class="mainTopContainer pt-0">
    <div class="totalBorder">
      <div class="mainGridTable mainTopGridTable">
        <div v-for="col in header" :key="col._id">
          <div
            style="width: 100%; height: 100%"
            class="gridThead"
            :class="{
              mainTopListright: col.value === 'margin',
              mainTopListHeaderBody: topBody.includes(col.value),
              mainTopListLeft: col.value === 'pur_month',
              mainTopTotalAreaBodyLeft: col.value === 'mainDay',
              mainTopTotalAreaHeader: totalArea.includes(col.value),
            }"
          >
            <div class="d-flex justify-center align-center" style="">
              <span
                v-if="
                  topBody.includes(col.value) &&
                  col.value !== 'pur_unit_price' &&
                  col.value !== 'sale_unit_price' &&
                  col.value !== 'pur_month' &&
                  col.value !== 'sale_month' &&
                  col.value !== 'sale_place' &&
                  col.value !== 'manu_unitprice'
                "
                >{{ col.text }}
              </span>
              <span v-if="col.value === 'pur_unit_price'">
                평균 <br />매입 단가</span
              >
              <span v-if="col.value === 'sale_unit_price'">
                평균 <br />매출 단가</span
              >
              <span v-if="col.value === 'pur_month'"> 매입</span>
              <span v-if="col.value === 'sale_month'"> 매출</span>
            </div>
          </div>
        </div>
      </div>
      <div class="mainGridTable mainTopGridTbody">
        <div v-for="col in header" :key="col._id">
          <div
            style="width: 100%; height: 100%"
            class="bodyItem d-flex justify-center"
            :class="{
              mainTopListBodyright: col.value === 'margin',
              mainTopListBody: topBody.includes(col.value),
              mainTopTotalAreaBodyLeft: col.value === 'mainDay',
              mainTopListBodyLeft: col.value === 'pur_month',
              mainTopTotalAreaBody: totalArea.includes(col.value),
            }"
          >
            <div v-if="col.value === 'day'">
              <span class="mainTopListTotalBody">TOTAL</span>
            </div>
            <div v-if="topBody.includes(col.value)">
              <div v-if="col.value === 'pur_month'">
                <span>{{ mainTotal.purLength ? mainTotal.purLength : 0 }}</span>
              </div>

              <div v-if="col.value === ''" class="text-center">
                <span>{{ purItem }}</span>
              </div>
              <div v-if="col.value === 'quantity'" class="text-center">
                <span>{{
                  ((mainTotal.quantity ? mainTotal.quantity : 0) + "y")
                    | makeComma
                }}</span>
              </div>
              <div v-if="col.value === 'manu_quantity'" class="text-center">
                <span>{{
                  ((mainTotal.manuQuantity ? mainTotal.manuQuantity : 0) + "y")
                    | makeComma
                }}</span>
              </div>
              <div v-if="col.value === 'inspect_quantity'" class="text-center">
                <span>{{
                  ((mainTotal.inspectQuantity ? mainTotal.inspectQuantity : 0) +
                    "y")
                    | makeComma
                }}</span>
              </div>
              <div v-if="col.value === 'rel_quantity'" class="text-center">
                <span>{{
                  ((mainTotal.relQuantity ? mainTotal.relQuantity : 0) + "y")
                    | makeComma
                }}</span>
              </div>
              <div v-if="col.value === 'pur_quantity'" class="text-center">
                <span>{{
                  ((mainTotal.purQuantity ? mainTotal.purQuantity : 0) + "y")
                    | makeComma
                }}</span>
              </div>
              <div v-if="col.value === 'pur_unit_price'">
                <div class="mainPriceloca">
                  <div class="">₩</div>
                  <div
                    class="mainTopPrice"
                    :class="purAvgUnitPrice < 0 ? 'minusPrice' : ''"
                  >
                    {{ purAvgUnitPrice | makeComma }}
                  </div>
                </div>
              </div>
              <div v-if="col.value === 'pur_price'" class="text-center">
                <div class="mainPriceloca">
                  <div class="">₩</div>
                  <div
                    class="mainTopPrice"
                    :class="mainTotal.purPrice < 0 ? 'minusPrice' : ''"
                  >
                    {{
                      mainTotal.purPrice ? mainTotal.purPrice : 0 | makeComma
                    }}
                  </div>
                </div>
              </div>
              <div v-if="col.value === 'sale_month'" class="text-center">
                <span>{{
                  mainTotal.salesLength ? mainTotal.salesLength : 0 | makeComma
                }}</span>
              </div>
              <!-- <div v-if="col.value === 'sale_place'" class="text-center">
                <span>{{ sale_place_length | makeComma }}</span>
              </div> -->
              <div v-if="col.value === 'sale_quantity'" class="text-center">
                <span>{{
                  ((mainTotal.salesQuantity ? mainTotal.salesQuantity : 0) +
                    "y")
                    | makeComma
                }}</span>
              </div>
              <div v-if="col.value === 'sale_unit_price'" class="text-center">
                <div class="mainPriceloca">
                  <div class="">₩</div>
                  <div
                    class="mainTopPrice"
                    :class="saleAvgUnitPrice < 0 ? 'minusPrice' : ''"
                  >
                    {{ saleAvgUnitPrice | makeComma }}
                  </div>
                </div>
              </div>
              <div v-if="col.value === 'sale_price'" class="text-center">
                <div class="mainPriceloca">
                  <div class="">₩</div>
                  <div
                    class="mainTopPrice"
                    :class="mainTotal.salePrice < 0 ? 'minusPrice' : ''"
                  >
                    {{
                      mainTotal.salePrice ? mainTotal.salePrice : 0 | makeComma
                    }}
                  </div>
                </div>
              </div>
              <div v-if="col.value === 'salesProfit'" class="text-center">
                <div class="mainPriceloca">
                  <div class="">₩</div>
                  <div
                    class="mainTopPrice"
                    :class="salesProfit < 0 ? 'minusPrice' : ''"
                  >
                    {{ salesProfit | makeComma }}
                  </div>
                </div>
              </div>
              <div v-if="col.value === 'margin'" class="text-center">
                <span>{{ margin | makeComma }}%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import header from "@/assets/table/main/homeHeaders";
import _ from "lodash";
export default {
  data() {
    return {
      header,
      totalArea: [
        "mainDay",
        "season",
        "year",
        "yaer",
        "type",
        "order",
        "buyer",
        "client",
        "liaison",
        "item",
        "day",
        "pur_place",
        "pur_item",
      ],
      topHeader: [
        "pur_length",
        "pur_month",
        "quantity",
        "manu_quantity",
        "manu_unitprice",
        "inspect_quantity",
        "rel_quantity",
        "pur_quantity",
        "pur_unit_price",
        "pur_price",
        "sale_length",
        "sale_month",
        "sale_place",
        "sale_quantity",
        "sale_unit_price",
        "sale_price",
        "salesProfit",
        "margin",
      ],
      topBody: [
        "pur_length",
        "pur_month",
        "quantity",
        "manu_quantity",
        "manu_unitprice",

        "manu_unitprice",
        "inspect_quantity",
        "rel_quantity",
        "pur_quantity",
        "pur_unit_price",
        "pur_price",
        "sale_length",
        "sale_month",
        "sale_place",
        "sale_quantity",
        "sale_unit_price",
        "sale_price",
        "salesProfit",
        "margin",
      ],
    };
  },
  computed: {
    ...mapGetters({
      mainTotal: "getMainTotal",
    }),

    orderList() {
      return this.$store.state.mainFilteredOrginList.length
        ? this.$store.state.mainFilteredOrginList
        : this.$store.state.mainOriginTotalList;
    },

    purItem() {
      return this.orderList.reduce((r, c) => r + (c.pur_item ? 1 : 0), 0);
    },

    purAvgUnitPrice() {
      return this.mainTotal.purPrice &&
        (this.mainTotal.purQuantity || this.mainTotal.relQuantity)
        ? parseFloat(
            (
              this.mainTotal.purPrice /
              (this.mainTotal.purQuantity
                ? this.mainTotal.purQuantity
                : this.mainTotal.relQuantity)
            ).toFixed(2),
          )
        : 0;
    },

    salesLength() {
      return this.orderList
        .filter((x) => x.order)
        .reduce(
          (r, c) => r + (c.sale_month !== "(비어있음)" && c.sale_month ? 1 : 0),
          0,
        );
    },
    sale_place_length() {
      return parseFloat(
        this.orderList.reduce((r, c) => r + (c.sale_place ? 1 : 0), 0),
      );
    },
    saleAvgUnitPrice() {
      return this.mainTotal.salePrice && this.mainTotal.salesQuantity
        ? parseFloat(
            (this.mainTotal.salePrice / this.mainTotal.salesQuantity)
              .toFixed(2)
              .replace(/(.?0+$)/, ""),
          )
        : 0;
    },
    salesProfit() {
      return this.roundsData(
        this.mainTotal.salePrice - this.mainTotal.purPrice,
      );
    },
    margin() {
      return this.mainTotal.salePrice
        ? parseFloat(
            ((this.salesProfit / this.mainTotal.salePrice) * 100).toFixed(1),
          )
        : 0;
    },
  },
};
</script>

<style></style>
