var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.dialog_detail,"max-width":"1000px"},on:{"click:outside":function($event){return _vm.$emit('DailogClose')},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.$emit('DailogClose')}}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"})]),_c('v-card-text',[_c('v-row',{staticClass:"my-1"},[_c('v-col',{attrs:{"cols":"9"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.select_item.order))])]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-2 pa-2",attrs:{"color":"blue darken-1","large":"","icon":""},on:{"click":function($event){return _vm.pageMove(
                  'order',
                  _vm.select_item.company,
                  _vm.select_item.order,
                  _vm.select_item._id
                )}}},[_c('v-icon',[_vm._v("mdi-arrow-right-bold")])],1),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.select_item.client && _vm.select_item.client.name)+", "+_vm._s(_vm.select_item.item && _vm.select_item.item.name)+" ("+_vm._s(_vm.select_item.item && _vm.select_item.item.name_production)+") ")])],1)],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"title"},[_vm._v("클레임")])]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-2 pa-2",attrs:{"color":"blue darken-1","large":"","icon":""},on:{"click":function($event){return _vm.pageMove(
                  'claim',
                  _vm.select_item.company,
                  _vm.select_item.order,
                  _vm.select_item._id
                )}}},[_c('v-icon',[_vm._v("mdi-arrow-right-bold")])],1),_c('v-col',{attrs:{"cols":"12"}},[_vm._v("총 "+_vm._s(_vm.select_item.claimLength)+"건 있습니다.")])],1)],1)],1),_c('v-divider'),_c('v-row',{staticClass:"my-1"},[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"title"},[_vm._v("매출 / 매입")])]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-2 pa-2",attrs:{"color":"blue darken-1","large":"","icon":""},on:{"click":function($event){return _vm.pageMove(
              'production',
              _vm.select_item.company,
              _vm.select_item.order,
              _vm.select_item._id
            )}}},[_c('v-icon',[_vm._v("mdi-arrow-right-bold")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.salesHeader,"items":_vm.select_item.sales,"item-key":"_id","hide-default-footer":"","disable-sort":"","no-data-text":"현재 마감 된 매출이 없습니다."},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.place_sales.name)+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("makeComma")(("" + (item.sales_total_quantity) + (_vm.select_item.unit_sign))))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("makeComma")(("" + (_vm.select_item.currency_sign) + (_vm.select_item.sales_unit_price))))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("makeComma")(("" + (_vm.select_item.currency_sign) + (item.sales_total_amount))))+" ")])])]}},{key:"body.append",fn:function(){return [_c('tr',[_c('th'),_c('th'),_c('th',{staticClass:"text-center"},[_vm._v("매출 합")]),_c('th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("makeComma")(("" + (_vm.select_item.currency_sign) + (_vm.select_item.salesSumTotalAmount ? _vm.select_item.salesSumTotalAmount : 0))))+" ")])])]},proxy:true}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.purchaseHeader,"items":_vm.select_item.purchase,"item-key":"_id","hide-default-footer":"","disable-sort":"","no-data-text":"현재 마감 된 매입이 없습니다."},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.place_forward.name)+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("makeComma")(("" + (item.total_quantity) + (_vm.select_item.unit_sign))))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("makeComma")(("" + (_vm.select_item.currency_sign) + (item.color[0].unit_price))))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("makeComma")(("" + (_vm.select_item.currency_sign) + (item.total_quantity * item.color[0].unit_price))))+" ")])])]}},{key:"body.append",fn:function(){return [_c('tr',[_c('th'),_c('th'),_c('th',{staticClass:"text-center"},[_vm._v("매입 합")]),_c('th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("makeComma")(("" + (_vm.select_item.currency_sign) + (_vm.select_item.purchase_amount ? _vm.select_item.purchase_amount : 0))))+" ")])]),_c('tr',[_c('th'),_c('th'),_c('th',{staticClass:"text-center"},[_vm._v("매출 이익")]),_c('th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("makeComma")(("" + (_vm.select_item.currency_sign) + (_vm.select_item.salesSumTotalAmount && _vm.select_item.purchase_amount ? _vm.select_item.salesSumTotalAmount - _vm.select_item.purchase_amount : 0))))+" ")])])]},proxy:true}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mt-1 pa-2",attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.$emit('DailogClose')}}},[_vm._v(" 닫기 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }