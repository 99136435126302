export default [
  {
    text: "매입처",
    value: "place_forward.name",
    width: "25%",
    align: "center",
  },
  {
    text: "수량",
    value: "total_quantity",
    width: "25%",
    align: "center",
  },
  {
    text: "단가",
    value: "color[0].unit_price",
    width: "25%",
    align: "center",
  },
  {
    text: "금액",
    value: "price",
    width: "25%",
    align: "center",
  },
];
