<template>
  <v-dialog v-model="isDialog" width="900px" persistent>
    <v-card>
      <div class="trialTitle">
        <div class="d-flex align-center">
          <span class="trialTitleText">구독 만료 안내</span>

          <v-btn @click="close" large class="ml-auto" icon color="red"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
      </div>
      <div class="pl-7 pr-7">
        <v-divider class="" />
      </div>
      <div class="trialBody">
        <div class="d-flex align-center">
          <v-icon size="30" color="red">mdi-bell-ring</v-icon
          ><span class="pl-4 trialCautionText"
            >무료체험기간이 만료되었습니다.</span
          >
        </div>

        <div class="trialBodyCard">
          귀사의 무료 체험 기간이 종료되었습니다. 계속해서 시스템의 모든 기능과
          서비스를 이용하시려면 구독을 갱신해주세요. <br />
          갱신 절차와 관련해 궁금하신 사항이 있으시면, 언제든지 고객센터에 문의
          부탁드립니다.
        </div>
        <div class="trialButtonArea">
          <div>
            <v-btn color="primary" @click="goPaymentPage()"
              >구독 갱신하기</v-btn
            >
          </div>
          <div>
            <v-btn @click="close()">나중에하기 </v-btn>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      isDialog: "getTrialAlramDialog",
    }),
  },
  methods: {
    close() {
      this.$store.commit("setTrialAlramDialog");
      this.$store.commit("setTrialClose", true);
    },
    goPaymentPage() {
      this.$store.commit("setNavSelected", { value: "pay" });
      this.$router.push({ path: "/setting" });
    },
  },
};
</script>
